export const environment = {
    logoMultipleColors: '/assets/icons/DMI_Logo.svg',
    logoSingleColor: '/assets/icons/DMI_Logo_Negatief.svg',
    applicationName: 'DMI Dashboard',
    production: true,
    apiUrl: 'https://admin.attributesportal.nl',
    clientId: '59fafa20189f61ae2059c111_4ovlzteer30gwgggkwsk00g44k88soo8sgwkk40g4kg4kkscow',
    clientSecret: '60h46xgt9yo8ss8skc0owgccsccw04k0scwksc0cooo00g8c4w',
    versionCheckURL: 'https://dmi.attributesportal.nl/version.json',
    sentryDSN: 'https://ceef0daa7a84447aad755c2522b231e8@o37556.ingest.sentry.io/5214097',
    sentryEnabled: false,
    environment: 'prod',
    config: {
        companyName: 'DMI',
        showProgressCard: true,
        showWorklogCard: false,
        autoApproveProduction: false,
        showMachineCounter: false,
        supportDefaultEmail: 'jeroen.stevens@maathof.nl',
        productionOrderProgressRequiresTypeUpdate: true,
        enablePrepareProductionOrder: false,
        enableQueueProductionOrder: false,
        enableCooperationProductionOrder: true,
        showOrderPrepareStartModal: true,
        favIconName: 'DMI_Logo.svg',
        worklogByCalculationNumber: true,
        constantMachine: false,
        filterCnc: false,
        showProductionOrderPdcStatus: "7,8,9,A,B",
    },
};
